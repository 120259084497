<template>
  <div class="news-detail" :class="{'has-bottom': !isApp}" v-if="detail">
    <div class="m-container">
      <h1 class="detail-title">{{ getText(detail, 'title') }}</h1>
      <video v-if="detail.resourceType === 'VIDEO'" class="detail-video" controls controlsList="nodownload">
        <source :src="detail.fileUrl" />
      </video>
      <audio v-if="detail.resourceType === 'VOICE'" class="detail-audio" controls controlsList="nodownload" :src="detail.fileUrl"></audio>
      <div class="detail-content" v-html="getText(detail, 'content')"></div>
    </div>
    <div class="app-download" v-if="!isApp">
      <div class="logo"><img src="../../assets/m-logo.png">众为App</div>
      <a :href="appDownload" class="btn">{{ $t('download') }}</a>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'Detail',
  data() {
    return {
      detail: null,
    }
  },
  watch: {
    $route() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  computed: {
    isApp() {
      let isApp = false
      if (this.$route.query.app == 'true') {
        isApp = true
      }
      return isApp
    },
    appDownload() {
      // 判断手机系统，返回下载地址
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        return process.env.VUE_APP_IOS_DOWNLOAD
      } else {
        return process.env.VUE_APP_ANDROID_DOWNLOAD
      }
    },
  },
  methods: {
    init() {
      // lang
      console.log(this.$route.query)
      const { lang, token } = this.$route.query
      if (token) {
        localStorage.setItem('token', token)
      }
      if (lang) {
        this.toggleLang(lang)
      }
      this.getNews()
    },
    toggleLang(lang) {
      if(lang == 'zh') {
        //中文
        localStorage.setItem('locale', 'zh')
        this.$i18n.locale = localStorage.getItem('locale')
      } else if(lang == 'en') {
        //英文
        localStorage.setItem('locale', 'en')
        this.$i18n.locale = localStorage.getItem('locale')
      }
    },
    getNews() {
      api.getNews(this.$route.params.id).then((res) => {
        this.detail = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.has-bottom {
  padding-bottom: 90px;
}
.app-download {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 15px;
  z-index: 999;
  box-shadow: 0px -20px 24px 0px rgba(0, 0, 0, 0.04);
}

.logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  img {
    width: 44px;
    height: auto;
    margin-right: 17px;
  }
}

.btn {
  min-width: 60px;
  height: 32px;
  padding: 0 10px;
  line-height: 32px;
  color: #fff;
  background: #002E5D;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
}

.m-container {
  max-width: 768px;
  padding: 0 15px;
  margin: 0 auto;
  padding: 15px;
}

.detail-title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
}

.detail-content {
  color: #5A5A5A;
  line-height: 22px;
  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
